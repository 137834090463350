<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          v-if="loading"
          :elevation="2" boilerplate type="card"
        ></v-skeleton-loader>
        <v-alert
          v-if="tickets.length === 0 && !loading"
          type="info"
          dismissible
        >
          No tickets found
        </v-alert>

        <v-card
          v-for="ticket in tickets"
          :key="ticket.id"

          class="mb-10"
          @click="openTicket(ticket)"
          :color="ticket.id === savedTicketID ? 'green' : 'white'"
          elevation="2"
          link
        >
          <v-card-title class="card-title">
            Ticket № {{ ticket.id }}
          </v-card-title>
          <v-card-text class="card-text">
            <p><b>Planned date:</b> {{ ticket.planned_date }}</p>
            <p><b>Shop number:</b> {{ ticket.store_number }}</p>
            <p>{{ ticket.customer_street }}</p>
            <p>{{ ticket.customer_zipcode }}, {{ ticket.customer_city }}</p>
            <p>Status: {{ ticket.status }}</p>
          </v-card-text>
          <v-card-actions v-if="ticket.id === savedTicketID">
            <v-btn
              block
              variant="outlined"
              color="white"
              @click.stop="openTicket(ticket)"
            >
              Proceed ticket
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import {useUserStore} from "@/stores/user";
import {useTicketStore} from '@/stores/tickets';

export default {
  data: () => ({
    userStore: useUserStore(),
    ticketStore: useTicketStore(),
    tickets: [],
    savedTicketID: false,
    currentPage: 1,
    perPage: 10,
    loading: true,
    totalPages: 0,
  }),
  mounted() {
    this.checkState()
    this.getTickets()
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async getTickets() {
      this.loading = true;
      try {
        const response = await axios.get(`/v2/tickets?page=${this.currentPage}&per_page=${this.perPage}`);
        if (response.data.data.current_page > response.data.data.last_page) {
          return;
        }
        this.tickets = this.tickets.concat(response.data.data.data);
        this.ticketStore.setTickets(this.tickets);
        this.currentPage++;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    checkState() {
      let data = localStorage.getItem('close_ticket')
      if (data) {
        try {
          data = JSON.parse(data);
          this.savedTicketID = parseInt(data.ticket_id);
        } catch (e) {
          console.error(e);
        }
      }
    },
    handleScroll() {
      const scrollPosition = window.scrollY + window.innerHeight;
      const pageHeight = document.documentElement.scrollHeight;
      if (scrollPosition >= pageHeight && !this.loading) {
        this.getTickets();
      }
    },
    openTicket(ticket) {
      this.$router.push({
        name: 'Ticket',
        params: {
          id: ticket.id
        }
      });
    }
  },
};

</script>

<style scoped>
.card-title {

}

.card-text p {
  padding-top: 0.5rem;
  font-size: 1rem;
}
</style>
