<template>
  <div style="min-height: 120%;">
    <p class="ticket-title">Task</p>
    <p class="ticket-text"><b>Company:</b> {{ ticket.company.name }}</p>
    <p class="ticket-text"><b>Shop №:</b> {{ ticket.shop.number }}</p>
    <p class="ticket-text">{{ ticket.customer_street }}</p>
    <p class="ticket-text">{{ ticket.customer_zipcode }}, {{ ticket.customer_city }}</p>
    <p class="ticket-text"><b>ToDo:</b></p>
    <v-divider></v-divider>
    <p class="ticket-text">{{ ticket.incident_message }}</p>
    <p class="ticket-text">--------- Kommentare ---------</p>
    <div v-for="comment in ticket.comments">
      <p class="ticket-text">{{ comment.comment }}</p>
    </div>
  </div>
  <div class="button-group">
    <button class="btn" style="font-size: 1.2rem; padding: 5px" @click="startRoute">
      Start Route
      <v-icon>mdi-map-marker</v-icon>
    </button>
    <button class="btn" @click="closeTicket">
      Close ticket
      <v-icon>mdi-upload</v-icon>
    </button>
  </div>
</template>

<script>
import {useTicketStore} from '@/stores/tickets';
import axios from "axios";

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    ticketStore: useTicketStore(),
    ticket: {
      company: {},
      shop: {},
    },
    closingTicket: false,
    ticket_fields: [],
    currentFieldIndex: 0,
    currentFieldType: 'text'
  }),
  created() {
    let ticket = this.ticketStore.getTicketById(Number(this.id))
    if (!ticket)
      return this.$router.push({path: '/'})
    this.ticket = ticket
  },
  mounted() {

  },
  methods: {
    closeTicket() {
      this.$router.push({
        name: 'CloseTicket',
        params: {
          ticket_id: this.ticket.id,
          company_id: this.ticket.company_id,
          project_id: this.ticket.project_id
        }
      });
    },
    startRoute() {
      window.open(`https://maps.google.com/maps?ll=${this.ticket.latitude},${this.ticket.longitude}&q=${this.ticket.latitude},${this.ticket.longitude}&hl=de&t=h&z=18`, '_blank');
    }

  }
};
</script>

<style scoped>
.ticket-title {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.ticket-text {
  margin: 1rem;
  font-size: 1.2rem;
}

.button-group {
  position: fixed;
  bottom: 0;
  left: 0;
}

.btn {
  display: block;
  padding-block: 0.75rem;
  width: calc(100vw - 2rem);
  margin: 1rem;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.disabled {
  background-color: lightgray;
  border-color: lightgray;
}
</style>
