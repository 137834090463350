import {defineStore} from 'pinia'

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    name: null,
    token: localStorage.getItem('token') || null,
    permission_id: null,
  }),
  getters: {
    isAuthenticated() {
      return !!this.token
    },
    getToken() {
      return this.token
    },
    isAdmin() {
      // TODO: extract from profile api later
      return this.permission_id === 1
    },
    isTech() {
      return this.permission_id === 2
    },
  },
  actions: {
    resetUser() {
      this.name = null
      this.token = null
      this.permission_id = null
      localStorage.removeItem('token')
    },
    setUser(name, token, permission_id) {
      this.name = name
      this.token = token
      this.permission_id = permission_id
      localStorage.setItem('token', token)
    },

  },
});
