import {createWebHashHistory, createRouter} from 'vue-router'

import Login from '../components/auth/Login.vue'
import Tickets from '../components/tickets/Tickets.vue'
import Ticket from "@/components/ticket/Ticket.vue";
import CloseTicket from "@/components/closeTicket/CloseTicket.vue";

const routes = [
  { path: '/', component: Tickets },
  { path: '/login', component: Login },
  {
    path: '/ticket/:id',
    name: 'Ticket',
    component: Ticket,
    props: true
  },
  {
    path: '/close-ticket/:ticket_id/:company_id/:project_id',
    name: 'CloseTicket',
    component: CloseTicket,
    props: true
  }
]

export default createRouter({
  history: createWebHashHistory(),
  routes,
})
