<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card theme="cucos" title="Login" :loading="loading">
          <v-card-text>
            <v-alert
              :type="message.type"
              dismissible
              elevation="2"
              icon="mdi-alert"
              class="mb-4"
              v-model="message.show"
              :text="message.text"
            ></v-alert>

            <v-form @submit.prevent="">
              <v-text-field
                v-model="email"
                label="Email"
                required
                type="email"
              />
              <v-text-field
                v-model="password"
                label="Password"
                required
                type="password"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="!email || !password"
              variant="tonal"
              size="large"
              @click="login"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import {useUserStore} from "@/stores/user";

export default {
  data: () => ({
    userStore: useUserStore(),
    email: null,
    password: null,
    loading: false,
    message: {
      show: false,
      type: 'warning',
      text: 'Incorrect email or password'
    },
  }),
  mounted() {
    console.log('Auth', this.userStore.isAuthenticated)
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        const response = await axios.post("/v1/authentication", {
          email: this.email,
          password: this.password,
        });
        await this.profile(response.data.data.token);
        this.$router.push('/');
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.showMessage('Incorrect email or password', 'warning')
        } else if (error.response && error.response.status === 403) {
          this.showMessage('Your account deactivated, contact support', 'warning')
        } else {
          this.showMessage('Something went wrong', 'error')
        }
      } finally {
        this.loading = false;
      }
    },
    async profile(token) {
      try {
        const response = await axios.get("/v1/profile", {
          headers: {token: token},
        });
        let res = response.data.data
        this.userStore.setUser(
          res.name,
          token,
          res.permission_id
        )
        console.log('Auth', this.userStore.isAuthenticated)
      } catch (error) {
        console.error(error);
      }
    },
    showMessage(message, type) {
      this.message.show = true;
      this.message.text = message;
      this.message.type = type;
    }
  },
};

</script>
