import { defineStore } from 'pinia';

export const useTicketStore = defineStore({
  id: 'ticket',
  state: () => ({
    tickets: [],
  }),
  actions: {
    getAllTickets() {
      return this.tickets;
    },
    getTicketById(id) {
      return this.tickets.find(ticket => ticket.id === id);
    },
    setTickets(tickets) {
      this.tickets = tickets;
    },
  },
});
