import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
  apiKey: '119b5066aeb16912bad14e3ec0ee9d0d',
  plugins: [new BugsnagPluginVue()]
})
BugsnagPerformance.start({ apiKey: '119b5066aeb16912bad14e3ec0ee9d0d' })

export default Bugsnag
