import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import {createVuetify} from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'cucos',
    themes: {
      cucos: {
        dark: false,
        primary: '#57bf35',
      },
    }
  },
})
